
import React from 'react';
import './Section13.css';

function Section13() {
  return (
    <>
   <section class="testimonial-section">
  <div class="container">
    <h2 class="section-title"> Oque nossos alunos dizem - Depoimentos</h2>
    <div class="testimonial-wrapper">
      <div class="testimonial">
        <div class="testimonial-video">
          <iframe src="https://www.youtube.com/embed/yGqWvvJb_9I?si=0h9nx2FSDTBflFXz" frameborder="0" allowfullscreen></iframe>
        </div>
       
      </div>
      <div class="testimonial">
        <div class="testimonial-video">
          <iframe src="https://www.youtube.com/embed/yGqWvvJb_9I?si=0h9nx2FSDTBflFXz" frameborder="0" allowfullscreen></iframe>
        </div>
       
      </div>
    </div>
  </div>
</section>


    </>
  );
}

export default Section13;
